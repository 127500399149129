import React from "react";
import { ChipIcon } from "@heroicons/react/solid";

export default function Experience() {
  return (
    <section id="experience">
      <div className="container px-5 py-10 mx-auto">
        <div className="text-center mb-20">
          <ChipIcon className="w-10 inline-block mb-4" />
          <h1 className="sm:text-4xl text-3xl font-medium title-font text-white mb-4">
            Experience
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
            <a
              href="https://docs.google.com/document/d/1soYnCmkfkO22wXsWYHKJY2LzgJ-kc6megfZxFANCmP8/edit"
              target="_blank"
              rel="noreferrer"
              className="inline-flex text-white bg-teal-500 border-0 py-2 px-6 focus:outline-none hover:bg-teal-600 rounded text-lg"
            >
              My Resume
            </a>
          </p>
        </div>
      </div>
    </section>
  );
}
